import ApiService from '@/services/api';

// TYPES

export type TUpdateRecordFullTranscriptionBody = unknown;

export type TUpdateRecordFullTranscriptionParams = {
  meeting_id?: string | number;
};

export type TUpdateRecordFullTranscriptionMaterials = {
  body?: TUpdateRecordFullTranscriptionBody;
  params?: TUpdateRecordFullTranscriptionParams;
};

export type TUpdateRecordFullTranscriptionResponse = unknown;

// FUNCTION

export const updateRecordFullTranscription = async ({
  body,
  params,
}: TUpdateRecordFullTranscriptionMaterials): Promise<TUpdateRecordFullTranscriptionResponse> => {
  const response = await ApiService.put(`/api/v2/fulltranscription`, body, { params });
  return response?.data;
};
