import ApiService from '@/services/api';

// TYPES

export type TDeleteCategoryParams = {
  id?: string;
};

export type TDeleteCategoryMaterials = {
  params?: TDeleteCategoryParams;
};

export type TDeleteCategoryResponse = unknown;

// FUNCTION

export const deleteCategory = async ({ params }: TDeleteCategoryMaterials): Promise<TDeleteCategoryResponse> => {
  const response = await ApiService.delete(`/api/v2/category`, { params });
  return response?.data;
};
