import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TAdminProps } from '@/layouts/Admin/Admin.types';
import Header from '@/containers/Header';
import Helpers from '@/services/helpers';
import { getMyProfileAction } from '@/redux/actions';

import './Admin.scss';

const Admin: React.FC<TAdminProps> = ({ children }) => {
  const dispatch = useDispatch();
  const atk = Helpers.getAccessToken();

  useEffect(() => {
    if (atk) dispatch(getMyProfileAction.request({}));
  }, [atk, dispatch]);

  return (
    <div className="Admin">
      <div className="Admin-header">
        <Header />
      </div>
      <div className="Admin-body">
        <div className="container">{children}</div>
      </div>
      <div className="Admin-footer text-right">
        Copyright ®2024 • Phần mềm được phát triển bởi <strong>Acazia Software Company</strong>. All Rights Reserved.
      </div>
    </div>
  );
};

export default Admin;
