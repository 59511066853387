import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteRecordAction } from '@/redux/actions';
import { deleteRecord, TDeleteRecordResponse } from '@/services/api';

// FUNCTION

export function* deleteRecordSaga(action: ActionType<typeof deleteRecordAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteRecord, materials);
    const deleteRecordResponse: TDeleteRecordResponse = response as TDeleteRecordResponse;
    yield put(deleteRecordAction.success(deleteRecordResponse));
    successCallback?.(deleteRecordResponse);
  } catch (err) {
    yield put(deleteRecordAction.failure(err));
    failedCallback?.(err);
  }
}
