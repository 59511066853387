import ApiService from '@/services/api';

// TYPES

export type TDeleteRecordParams = {
  id?: string | number;
};

export type TDeleteRecordMaterials = {
  params?: TDeleteRecordParams;
};

export type TDeleteRecordResponse = unknown;

// FUNCTION

export const deleteRecord = async ({ params }: TDeleteRecordMaterials): Promise<TDeleteRecordResponse> => {
  const response = await ApiService.delete(`/api/v2/meeting`, { params });
  return response?.data;
};
