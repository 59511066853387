import React from 'react';
import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';

import { TModalProps } from '@/components/Modal/Modal.types';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import Button, { EButtonStyleType } from '@/components/Button';

import './Modal.scss';

const Modal: React.FC<TModalProps> = ({
  visible,
  centered,
  width,
  wrapClassName,
  className,
  closeable,
  children,
  title,
  cancelButtonProps,
  confirmButtonProps,
  showFooter,
  loading,
  onClose,
  onSubmit,
}) => {
  return (
    <AntdModal
      footer={null}
      title={null}
      closable={closeable}
      visible={visible}
      width={width}
      centered={centered}
      onCancel={!loading ? onClose : undefined}
      wrapClassName={classNames('Modal-wrapper', wrapClassName)}
      className={classNames('Modal', className)}
      closeIcon={<Icon name={EIconName.X} color={EIconColor.DOVE_GRAY} />}
    >
      {title && (
        <div className="Modal-header">
          <div className="Modal-header-title text-center">{title}</div>
        </div>
      )}

      <div className="Modal-body">{children}</div>

      {showFooter && (
        <div className="Modal-submit flex items-center">
          <Button
            title="Huỷ"
            styleType={EButtonStyleType.GALLERY}
            onClick={onClose}
            {...cancelButtonProps}
            disabled={loading}
          />
          <Button
            title="Đồng Ý"
            styleType={EButtonStyleType.TORY_BLUE}
            {...confirmButtonProps}
            onClick={onSubmit}
            loading={loading}
          />
        </div>
      )}
    </AntdModal>
  );
};

export default Modal;
