import ApiService from '@/services/api';

// TYPES

export type TUploadFileRecordParams = unknown;
export type TUploadFileRecordBody = unknown;

export type TUploadFileRecordMaterials = {
  params?: TUploadFileRecordParams;
  body?: TUploadFileRecordBody;
};

export type TUploadFileRecordResponse = unknown;

// FUNCTION

export const uploadFileRecord = async ({
  params,
  body,
}: TUploadFileRecordMaterials): Promise<TUploadFileRecordResponse> => {
  const response = await ApiService.post(`/api/v2/meeting-upload-url`, body, { params });
  return response?.data;
};
