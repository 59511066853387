import ApiService from '@/services/api';

// TYPES

export type TGetCategoriesParams = unknown;

export type TGetCategoriesMaterials = {
  params?: TGetCategoriesParams;
};

export type TGetCategoriesResponse = unknown;

// FUNCTION

export const getCategories = async ({ params }: TGetCategoriesMaterials): Promise<TGetCategoriesResponse> => {
  const response = await ApiService.get(`/api/v2/category`, { params });
  return response?.data;
};
