import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { uploadFileRecordAction } from '@/redux/actions';
import { uploadFileRecord, TUploadFileRecordResponse } from '@/services/api';

// FUNCTION

export function* uploadFileRecordSaga(action: ActionType<typeof uploadFileRecordAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(uploadFileRecord, materials);
    const uploadFileRecordResponse: TUploadFileRecordResponse = response as TUploadFileRecordResponse;
    yield put(uploadFileRecordAction.success(uploadFileRecordResponse));
    successCallback?.(uploadFileRecordResponse);
  } catch (err) {
    yield put(uploadFileRecordAction.failure(err));
    failedCallback?.(err);
  }
}
