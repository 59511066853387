import ApiService from '@/services/api';

// TYPES

export type TGetRecordsParams = unknown;

export type TGetRecordsMaterials = {
  params?: TGetRecordsParams;
};

export type TGetRecordsResponse = unknown;

// FUNCTION

export const getRecords = async ({ params }: TGetRecordsMaterials): Promise<TGetRecordsResponse> => {
  const response = await ApiService.get(`/api/v2/meeting`, { params });
  return response?.data;
};
