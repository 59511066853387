export enum EIconName {
  Microphone = 'Microphone',
  DotsVertical = 'DotsVertical',
  Settings = 'Settings',
  Search = 'Search',
  ListSearch = 'ListSearch',
  Filter = 'Filter',
  Plus = 'Plus',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  Dots = 'Dots',
  Edit = 'Edit',
  Trash = 'Trash',
  Category = 'Category',
  Server = 'Server',
  Copyright = 'Copyright',
  Logout = 'Logout',
  User = 'User',
  Eye = 'Eye',
  EyeOff = 'EyeOff',
  X = 'X',
  AlertCircleFill = 'AlertCircleFill',
  AlertTriangleFill = 'AlertTriangleFill',
  CircleCheckFill = 'CircleCheckFill',
  CloudUpload = 'CloudUpload',
  Menu = 'Menu',
}

export enum EIconColor {
  BLACK = '#000',
  WHITE = '#fff',

  DOVE_GRAY = '#696969',
  TORY_BLUE = '#0d5d98',
  FLAME_PEA = '#dc5230',
  SUNSET_ORANGE = '#ff4d4f',
  GREEN_HAZE = '#00a650',
  AMBER = '#ffbf00',
}
