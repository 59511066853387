import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRecordFullTranscriptionAction } from '@/redux/actions';
import { getRecordFullTranscription, TGetRecordFullTranscriptionResponse } from '@/services/api';

// FUNCTION

export function* getRecordFullTranscriptionSaga(
  action: ActionType<typeof getRecordFullTranscriptionAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRecordFullTranscription, materials);
    const getRecordFullTranscriptionResponse: TGetRecordFullTranscriptionResponse =
      response as TGetRecordFullTranscriptionResponse;
    yield put(getRecordFullTranscriptionAction.success(getRecordFullTranscriptionResponse));
    successCallback?.(getRecordFullTranscriptionResponse);
  } catch (err) {
    yield put(getRecordFullTranscriptionAction.failure(err));
    failedCallback?.(err);
  }
}
