import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateRecordAction } from '@/redux/actions';
import { updateRecord, TUpdateRecordResponse } from '@/services/api';

// FUNCTION

export function* updateRecordSaga(action: ActionType<typeof updateRecordAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateRecord, materials);
    const updateRecordResponse: TUpdateRecordResponse = response as TUpdateRecordResponse;
    yield put(updateRecordAction.success(updateRecordResponse));
    successCallback?.(updateRecordResponse);
  } catch (err) {
    yield put(updateRecordAction.failure(err));
    failedCallback?.(err);
  }
}
