import ApiService from '@/services/api';

// TYPES

export type TGetAudioRecordParams = unknown;

export type TGetAudioRecordMaterials = {
  params?: TGetAudioRecordParams;
};

export type TGetAudioRecordResponse = {
  duration: string;
  object: string;
  url: string;
};

// FUNCTION

export const getAudioRecord = async ({ params }: TGetAudioRecordMaterials): Promise<TGetAudioRecordResponse> => {
  const response = await ApiService.get(`/api/v2/audio`, { params });
  return response?.data;
};
