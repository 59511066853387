import { TUser } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTokenParams = unknown;
export type TGetTokenBody = unknown;

export type TGetTokenMaterials = {
  params?: TGetTokenParams;
  body?: TGetTokenBody;
  token?: string;
};

export type TGetTokenResponse = TUser;

// FUNCTION

export const getToken = async ({ params, body, token }: TGetTokenMaterials): Promise<TGetTokenResponse> => {
  const response = await ApiService.post(`/api/v2/token`, body, {
    params,
    headers: {
      token,
    },
  });
  return response?.data;
};
