import { TUser } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetMyProfileParams = unknown;

export type TGetMyProfileMaterials = {
  params?: TGetMyProfileParams;
};

export type TGetMyProfileResponse = TUser;

// FUNCTION

export const getMyProfile = async ({ params }: TGetMyProfileMaterials): Promise<TGetMyProfileResponse> => {
  const response = await ApiService.get(`/api/v2/user`, { params });
  return response?.data;
};
