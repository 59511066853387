import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getTokenAction } from '@/redux/actions';
import { getToken, TGetTokenResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* getTokenSaga(action: ActionType<typeof getTokenAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getToken, materials);
    const getTokenResponse: TGetTokenResponse = response as TGetTokenResponse;

    Helpers.storeAccessToken(getTokenResponse?.access_token || '');

    yield put(getTokenAction.success(getTokenResponse));
    successCallback?.(getTokenResponse);
  } catch (err) {
    yield put(getTokenAction.failure(err));
    failedCallback?.(err);
  }
}
