import React from 'react';
import classNames from 'classnames';

import Icon, { EIconColor, EIconName } from '@/components/Icon';

import { EBannerType } from './Banner.enums';
import { TBannerProps } from './Banner.types.d';
import './Banner.scss';

const Banner: React.FC<TBannerProps> = ({ type, content }) => {
  const renderIconName = (): EIconName => {
    switch (type) {
      case EBannerType.SUCCESS:
        return EIconName.CircleCheckFill;
      case EBannerType.WARNING:
      case EBannerType.ERROR:
        return EIconName.AlertTriangleFill;
      default:
        return EIconName.AlertCircleFill;
    }
  };

  const renderIconColor = (): EIconColor => {
    switch (type) {
      case EBannerType.SUCCESS:
        return EIconColor.GREEN_HAZE;
      case EBannerType.WARNING:
        return EIconColor.AMBER;
      case EBannerType.ERROR:
        return EIconColor.SUNSET_ORANGE;
      default:
        return EIconColor.TORY_BLUE;
    }
  };

  return (
    <div className={classNames('Banner flex items-start', type)}>
      <div className="Banner-icon">
        <Icon name={renderIconName()} color={renderIconColor()} />
      </div>
      <div className="Banner-content">{content}</div>
    </div>
  );
};

export default Banner;
