import { all, takeLatest } from 'redux-saga/effects';

import { getMyProfileAction, getTokenAction, loginAction } from '@/redux/actions';

import { getMyProfileSaga } from './get-my-profile';
import { getTokenSaga } from './get-token';
import { loginSaga } from './login';

export default function* root(): Generator {
  yield all([
    takeLatest(getMyProfileAction.request.type, getMyProfileSaga),
    takeLatest(getTokenAction.request.type, getTokenSaga),
    takeLatest(loginAction.request.type, loginSaga),
  ]);
}
