import { createActionCreator } from 'deox';

import { TCreateRecordMaterials, TCreateRecordResponse } from '@/services/api/record/create-record';

// CONSTANTS

export enum ECreateRecordAction {
  CREATE_RECORD = 'CREATE_RECORD',
  CREATE_RECORD_REQUEST = 'CREATE_RECORD_REQUEST',
  CREATE_RECORD_SUCCESS = 'CREATE_RECORD_SUCCESS',
  CREATE_RECORD_FAILED = 'CREATE_RECORD_FAILED',
}

// TYPES

export type TCreateRecordRequest = {
  type: ECreateRecordAction.CREATE_RECORD_REQUEST;
  payload: {
    materials: TCreateRecordMaterials;
    successCallback?: (response: TCreateRecordResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateRecordSuccess = {
  type: ECreateRecordAction.CREATE_RECORD_SUCCESS;
  payload: { response: TCreateRecordResponse };
};

export type TCreateRecordFailed = { type: ECreateRecordAction.CREATE_RECORD_FAILED };

// FUNCTION

export const createRecordAction = {
  request: createActionCreator(
    ECreateRecordAction.CREATE_RECORD_REQUEST,
    (resolve) =>
      (
        materials: TCreateRecordMaterials,
        successCallback?: (response: TCreateRecordResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateRecordRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateRecordAction.CREATE_RECORD_SUCCESS,
    (resolve) =>
      (response: TCreateRecordResponse): TCreateRecordSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateRecordAction.CREATE_RECORD_FAILED,
    (resolve) =>
      (error: unknown): TCreateRecordFailed =>
        resolve({ error }),
  ),
};
