import ApiService from '@/services/api';

// TYPES

export type TCreateRecordParams = unknown;
export type TCreateRecordBody = unknown;

export type TCreateRecordMaterials = {
  params?: TCreateRecordParams;
  body?: TCreateRecordBody;
};

export type TCreateRecordResponse = unknown;

// FUNCTION

export const createRecord = async ({ params, body }: TCreateRecordMaterials): Promise<TCreateRecordResponse> => {
  const response = await ApiService.post(`/api/v2/meeting`, body, { params });
  return response?.data;
};
