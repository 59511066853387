import { all, fork } from 'redux-saga/effects';

import authSaga from './auth';
import categorySaga from './category';
import recordSaga from './record';

const rootSaga = function* root(): Generator {
  yield all([fork(authSaga), fork(categorySaga), fork(recordSaga)]);
};

export default rootSaga;
