import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';

import Logo from '@/assets/images/logo.svg';
import { LayoutPaths, Paths } from '@/pages/routers';
import Button, { EButtonStyleType } from '@/components/Button';
import { EIconName } from '@/components/Icon';
import DropdownMenu from '@/components/DropdownMenu';
import ModalConfirm from '@/containers/ModalConfirm';
import { EBannerType } from '@/components/Banner';
import { getMyProfileAction } from '@/redux/actions';
import Helpers from '@/services/helpers';

import { THeaderProps } from './Header.types.d';
import './Header.scss';

const Header: React.FC<THeaderProps> = () => {
  const dispatch = useDispatch();
  const [visibleModalLogout, setVisibleModalLogout] = useState<boolean>(false);

  const handleOpenModalLogout = (): void => {
    setVisibleModalLogout(true);
  };

  const handleCloseModalLogout = (): void => {
    setVisibleModalLogout(false);
  };

  const handleLogout = (): void => {
    dispatch(getMyProfileAction.success(undefined));
    Helpers.clearTokens();
    navigate(LayoutPaths.Auth);
  };

  const dataSettingsDropdown = [
    {
      value: 'category',
      label: 'Chuyên mục ghi âm',
      iconName: EIconName.Category,
      onClick: (): void => {
        navigate(Paths.Category);
      },
    },
    // {
    //   value: 'ip-config',
    //   label: 'Cấu hình địa chỉ IP',
    //   iconName: EIconName.Server,
    // },
    // {
    //   value: 'licences',
    //   label: 'Giấy phép sử dụng',
    //   iconName: EIconName.Copyright,
    // },
    // {
    //   value: 'system-setting',
    //   label: 'Cấu hình hệ thống',
    //   iconName: EIconName.Settings,
    // },
    {
      value: 'line',
      line: true,
    },
    // {
    //   value: 'user-information',
    //   label: 'Thông tin thành viên',
    //   iconName: EIconName.User,
    // },
    {
      value: 'logout',
      label: 'Đăng xuất',
      danger: true,
      iconName: EIconName.Logout,
      onClick: handleOpenModalLogout,
    },
  ];

  return (
    <div className="Header">
      <div className="container">
        <div className="Header-wrapper flex items-center justify-between">
          <div className="Header-wrapper-item">
            <Link to={Paths.Dashboard} className="Header-logo">
              <img src={Logo} alt="" />
              <div className="Header-logo-info">Phần mềm chuyển đổi giọng nói thành văn bản</div>
            </Link>
          </div>
          <div className="Header-wrapper-item">
            <Row gutter={[8, 8]}>
              {/* <Col>
                <Button
                  title="Tải File Ghi Âm"
                  styleType={EButtonStyleType.TORY_BLUE}
                  iconName={EIconName.Microphone}
                />
              </Col> */}
              <Col>
                <DropdownMenu options={dataSettingsDropdown} placement="bottomRight">
                  <Button styleType={EButtonStyleType.GALLERY} iconName={EIconName.Menu} />
                </DropdownMenu>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <ModalConfirm
        type={EBannerType.INFO}
        visible={visibleModalLogout}
        title="Đăng Xuất"
        content="Bạn có chắc chắn muốn đăng xuất?"
        confirmButtonProps={{ title: 'Đăng Xuất' }}
        onClose={handleCloseModalLogout}
        onSubmit={handleLogout}
      />
    </div>
  );
};

export default Header;
