import { TTranscript } from '@/common/models';
import { TCommonPaginate } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetRecordFullTranscriptionParams = unknown;

export type TGetRecordFullTranscriptionMaterials = {
  params?: TGetRecordFullTranscriptionParams;
};

export type TGetRecordFullTranscriptionResponse = TCommonPaginate & {
  data: TTranscript[];
};

// FUNCTION

export const getRecordFullTranscription = async ({
  params,
}: TGetRecordFullTranscriptionMaterials): Promise<TGetRecordFullTranscriptionResponse> => {
  const response = await ApiService.get(`/api/v2/fulltranscription`, { params });
  return response?.data;
};
