import { TRecordState } from '@/redux/reducers/record';
import { TUpdateRecordFullTranscriptionSuccess } from '@/redux/actions/record';

export const updateRecordFullTranscriptionUpdateState = (
  state: TRecordState,
  action: TUpdateRecordFullTranscriptionSuccess,
): TRecordState => ({
  ...state,
  updateRecordFullTranscriptionResponse: action.payload.response,
});
