import { createActionCreator } from 'deox';

import { TGetRecordsMaterials, TGetRecordsResponse } from '@/services/api/record/get-records';

// CONSTANTS

export enum EGetRecordsAction {
  GET_RECORDS = 'GET_RECORDS',
  GET_RECORDS_REQUEST = 'GET_RECORDS_REQUEST',
  GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS',
  GET_RECORDS_FAILED = 'GET_RECORDS_FAILED',
}

// TYPES

export type TGetRecordsRequest = {
  type: EGetRecordsAction.GET_RECORDS_REQUEST;
  payload: {
    materials: TGetRecordsMaterials;
    successCallback?: (response: TGetRecordsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRecordsSuccess = {
  type: EGetRecordsAction.GET_RECORDS_SUCCESS;
  payload: { response: TGetRecordsResponse };
};

export type TGetRecordsFailed = { type: EGetRecordsAction.GET_RECORDS_FAILED };

// FUNCTION

export const getRecordsAction = {
  request: createActionCreator(
    EGetRecordsAction.GET_RECORDS_REQUEST,
    (resolve) =>
      (
        materials: TGetRecordsMaterials,
        successCallback?: (response: TGetRecordsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRecordsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRecordsAction.GET_RECORDS_SUCCESS,
    (resolve) =>
      (response: TGetRecordsResponse): TGetRecordsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRecordsAction.GET_RECORDS_FAILED,
    (resolve) =>
      (error: unknown): TGetRecordsFailed =>
        resolve({ error }),
  ),
};
