import { createActionCreator } from 'deox';

import { TDeleteRecordMaterials, TDeleteRecordResponse } from '@/services/api/record/delete-record';

// CONSTANTS

export enum EDeleteRecordAction {
  DELETE_RECORD = 'DELETE_RECORD',
  DELETE_RECORD_REQUEST = 'DELETE_RECORD_REQUEST',
  DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS',
  DELETE_RECORD_FAILED = 'DELETE_RECORD_FAILED',
}

// TYPES

export type TDeleteRecordRequest = {
  type: EDeleteRecordAction.DELETE_RECORD_REQUEST;
  payload: {
    materials: TDeleteRecordMaterials;
    successCallback?: (response: TDeleteRecordResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteRecordSuccess = {
  type: EDeleteRecordAction.DELETE_RECORD_SUCCESS;
  payload: { response: TDeleteRecordResponse };
};

export type TDeleteRecordFailed = { type: EDeleteRecordAction.DELETE_RECORD_FAILED };

// FUNCTION

export const deleteRecordAction = {
  request: createActionCreator(
    EDeleteRecordAction.DELETE_RECORD_REQUEST,
    (resolve) =>
      (
        materials: TDeleteRecordMaterials,
        successCallback?: (response: TDeleteRecordResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteRecordRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteRecordAction.DELETE_RECORD_SUCCESS,
    (resolve) =>
      (response: TDeleteRecordResponse): TDeleteRecordSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteRecordAction.DELETE_RECORD_FAILED,
    (resolve) =>
      (error: unknown): TDeleteRecordFailed =>
        resolve({ error }),
  ),
};
