import { createActionCreator } from 'deox';

import { TUploadFileRecordMaterials, TUploadFileRecordResponse } from '@/services/api/record/upload-file-record';

// CONSTANTS

export enum EUploadFileRecordAction {
  UPLOAD_FILE_RECORD = 'UPLOAD_FILE_RECORD',
  UPLOAD_FILE_RECORD_REQUEST = 'UPLOAD_FILE_RECORD_REQUEST',
  UPLOAD_FILE_RECORD_SUCCESS = 'UPLOAD_FILE_RECORD_SUCCESS',
  UPLOAD_FILE_RECORD_FAILED = 'UPLOAD_FILE_RECORD_FAILED',
}

// TYPES

export type TUploadFileRecordRequest = {
  type: EUploadFileRecordAction.UPLOAD_FILE_RECORD_REQUEST;
  payload: {
    materials: TUploadFileRecordMaterials;
    successCallback?: (response: TUploadFileRecordResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUploadFileRecordSuccess = {
  type: EUploadFileRecordAction.UPLOAD_FILE_RECORD_SUCCESS;
  payload: { response: TUploadFileRecordResponse };
};

export type TUploadFileRecordFailed = { type: EUploadFileRecordAction.UPLOAD_FILE_RECORD_FAILED };

// FUNCTION

export const uploadFileRecordAction = {
  request: createActionCreator(
    EUploadFileRecordAction.UPLOAD_FILE_RECORD_REQUEST,
    (resolve) =>
      (
        materials: TUploadFileRecordMaterials,
        successCallback?: (response: TUploadFileRecordResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUploadFileRecordRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUploadFileRecordAction.UPLOAD_FILE_RECORD_SUCCESS,
    (resolve) =>
      (response: TUploadFileRecordResponse): TUploadFileRecordSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUploadFileRecordAction.UPLOAD_FILE_RECORD_FAILED,
    (resolve) =>
      (error: unknown): TUploadFileRecordFailed =>
        resolve({ error }),
  ),
};
