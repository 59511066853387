import { createActionCreator } from 'deox';

import { TUpdateRecordMaterials, TUpdateRecordResponse } from '@/services/api/record/update-record';

// CONSTANTS

export enum EUpdateRecordAction {
  UPDATE_RECORD = 'UPDATE_RECORD',
  UPDATE_RECORD_REQUEST = 'UPDATE_RECORD_REQUEST',
  UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS',
  UPDATE_RECORD_FAILED = 'UPDATE_RECORD_FAILED',
}

// TYPES

export type TUpdateRecordRequest = {
  type: EUpdateRecordAction.UPDATE_RECORD_REQUEST;
  payload: {
    materials: TUpdateRecordMaterials;
    successCallback?: (response: TUpdateRecordResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRecordSuccess = {
  type: EUpdateRecordAction.UPDATE_RECORD_SUCCESS;
  payload: { response: TUpdateRecordResponse };
};

export type TUpdateRecordFailed = { type: EUpdateRecordAction.UPDATE_RECORD_FAILED };

// FUNCTION

export const updateRecordAction = {
  request: createActionCreator(
    EUpdateRecordAction.UPDATE_RECORD_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRecordMaterials,
        successCallback?: (response: TUpdateRecordResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRecordRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRecordAction.UPDATE_RECORD_SUCCESS,
    (resolve) =>
      (response: TUpdateRecordResponse): TUpdateRecordSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRecordAction.UPDATE_RECORD_FAILED,
    (resolve) =>
      (error: unknown): TUpdateRecordFailed =>
        resolve({ error }),
  ),
};
