import { EAppointmentStatus } from '@/common/enums';
import { ETagType } from '@/components/Tag';

/* eslint-disable no-useless-escape */
export const REGEX = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  domain: /^[a-zA-Z0-9](?:[a-zA-Z0-9-.]*[a-zA-Z0-9])?$/i,
  alphabetic: /^[a-z\s]+$/i,
  alphanumerial: /^[a-z0-9\s]+$/i,
  numeric: /^\d+$/i,
  onlySpecialKey: /[$&+,:;=?@#|'<>.^*()%`~_!\-"\]\[\\}{'/]/g,
};

export const dataTablePerPageOptions = [
  { label: '10 / trang', value: '10' },
  { label: '25 / trang', value: '25' },
  { label: '50 / trang', value: '50' },
  { label: '75 / trang', value: '75' },
  { label: '100 / trang', value: '100' },
];

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const dataPublicOptions = [
  { label: 'Công khai', value: 'true' },
  { label: 'Riêng tư', value: 'false' },
];

export const dataAppointmentStatus = [
  { value: EAppointmentStatus.CREATED, label: 'Đã tạo', data: { tagType: ETagType.PROCESS } },
  { value: EAppointmentStatus.QUEUED, label: 'Đang nhận diện', data: { tagType: ETagType.PENDING } },
  { value: EAppointmentStatus.PENDING, label: 'Đang chờ', data: { tagType: ETagType.PENDING } },
  { value: EAppointmentStatus.PROCESS, label: 'Đang xử lý', data: { tagType: ETagType.PENDING } },
  { value: EAppointmentStatus.SUCCESS, label: 'Hoàn thành', data: { tagType: ETagType.SUCCESS } },
  { value: EAppointmentStatus.ERROR, label: 'Lỗi', data: { tagType: ETagType.ERROR } },
];
