import { createActionCreator } from 'deox';

import { TGetTokenMaterials, TGetTokenResponse } from '@/services/api/auth/get-token';

// CONSTANTS

export enum EGetTokenAction {
  GET_TOKEN = 'GET_TOKEN',
  GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST',
  GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS',
  GET_TOKEN_FAILED = 'GET_TOKEN_FAILED',
}

// TYPES

export type TGetTokenRequest = {
  type: EGetTokenAction.GET_TOKEN_REQUEST;
  payload: {
    materials: TGetTokenMaterials;
    successCallback?: (response: TGetTokenResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTokenSuccess = {
  type: EGetTokenAction.GET_TOKEN_SUCCESS;
  payload: { response: TGetTokenResponse };
};

export type TGetTokenFailed = { type: EGetTokenAction.GET_TOKEN_FAILED };

// FUNCTION

export const getTokenAction = {
  request: createActionCreator(
    EGetTokenAction.GET_TOKEN_REQUEST,
    (resolve) =>
      (
        materials: TGetTokenMaterials,
        successCallback?: (response: TGetTokenResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTokenRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTokenAction.GET_TOKEN_SUCCESS,
    (resolve) =>
      (response: TGetTokenResponse): TGetTokenSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTokenAction.GET_TOKEN_FAILED,
    (resolve) =>
      (error: unknown): TGetTokenFailed =>
        resolve({ error }),
  ),
};
