import { TUser } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TLoginParams = unknown;
export type TLoginBody = unknown;

export type TLoginMaterials = {
  params?: TLoginParams;
  body?: TLoginBody;
};

export type TLoginResponse = TUser;

// FUNCTION

export const login = async ({ params, body }: TLoginMaterials): Promise<TLoginResponse> => {
  const response = await ApiService.post(`/api/v2/login-vov`, body, { params });
  return response?.data;
};
