import ApiService from '@/services/api';

// TYPES

export type TUpdateRecordBody = unknown;

export type TUpdateRecordParams = {
  id?: string | number;
};

export type TUpdateRecordMaterials = {
  body?: TUpdateRecordBody;
  params?: TUpdateRecordParams;
};

export type TUpdateRecordResponse = unknown;

// FUNCTION

export const updateRecord = async ({ body, params }: TUpdateRecordMaterials): Promise<TUpdateRecordResponse> => {
  const response = await ApiService.put(`/api/v2/meeting`, body, { params });
  return response?.data;
};
