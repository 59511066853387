import { createActionCreator } from 'deox';

import {
  TGetRecordFullTranscriptionMaterials,
  TGetRecordFullTranscriptionResponse,
} from '@/services/api/record/get-record-full-transcription';

// CONSTANTS

export enum EGetRecordFullTranscriptionAction {
  GET_RECORD_FULL_TRANSCRIPTION = 'GET_RECORD_FULL_TRANSCRIPTION',
  GET_RECORD_FULL_TRANSCRIPTION_REQUEST = 'GET_RECORD_FULL_TRANSCRIPTION_REQUEST',
  GET_RECORD_FULL_TRANSCRIPTION_SUCCESS = 'GET_RECORD_FULL_TRANSCRIPTION_SUCCESS',
  GET_RECORD_FULL_TRANSCRIPTION_FAILED = 'GET_RECORD_FULL_TRANSCRIPTION_FAILED',
}

// TYPES

export type TGetRecordFullTranscriptionRequest = {
  type: EGetRecordFullTranscriptionAction.GET_RECORD_FULL_TRANSCRIPTION_REQUEST;
  payload: {
    materials: TGetRecordFullTranscriptionMaterials;
    successCallback?: (response: TGetRecordFullTranscriptionResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetRecordFullTranscriptionSuccess = {
  type: EGetRecordFullTranscriptionAction.GET_RECORD_FULL_TRANSCRIPTION_SUCCESS;
  payload: { response: TGetRecordFullTranscriptionResponse };
};

export type TGetRecordFullTranscriptionFailed = {
  type: EGetRecordFullTranscriptionAction.GET_RECORD_FULL_TRANSCRIPTION_FAILED;
};

// FUNCTION

export const getRecordFullTranscriptionAction = {
  request: createActionCreator(
    EGetRecordFullTranscriptionAction.GET_RECORD_FULL_TRANSCRIPTION_REQUEST,
    (resolve) =>
      (
        materials: TGetRecordFullTranscriptionMaterials,
        successCallback?: (response: TGetRecordFullTranscriptionResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetRecordFullTranscriptionRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetRecordFullTranscriptionAction.GET_RECORD_FULL_TRANSCRIPTION_SUCCESS,
    (resolve) =>
      (response: TGetRecordFullTranscriptionResponse): TGetRecordFullTranscriptionSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetRecordFullTranscriptionAction.GET_RECORD_FULL_TRANSCRIPTION_FAILED,
    (resolve) =>
      (error: unknown): TGetRecordFullTranscriptionFailed =>
        resolve({ error }),
  ),
};
