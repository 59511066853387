import ApiService from '@/services/api';

// TYPES

export type TUpdateCategoryBody = unknown;

export type TUpdateCategoryParams = {
  id?: string;
};

export type TUpdateCategoryMaterials = {
  body?: TUpdateCategoryBody;
  params?: TUpdateCategoryParams;
};

export type TUpdateCategoryResponse = unknown;

// FUNCTION

export const updateCategory = async ({ body, params }: TUpdateCategoryMaterials): Promise<TUpdateCategoryResponse> => {
  const response = await ApiService.put(`/api/v2/category`, body, { params });
  return response?.data;
};
