import { createReducer } from 'deox';

import {
  TCreateRecordResponse,
  TDeleteRecordResponse,
  TGetAudioRecordResponse,
  TGetRecordFullTranscriptionResponse,
  TGetRecordsResponse,
  TUpdateRecordFullTranscriptionResponse,
  TUpdateRecordResponse,
  TUploadFileRecordResponse,
} from '@/services/api/record';
import {
  createRecordAction,
  deleteRecordAction,
  getAudioRecordAction,
  getRecordFullTranscriptionAction,
  getRecordsAction,
  updateRecordFullTranscriptionAction,
  updateRecordAction,
  uploadFileRecordAction,
} from '@/redux/actions';
import { createRecordUpdateState } from './create-record';
import { deleteRecordUpdateState } from './delete-record';
import { getAudioRecordUpdateState } from './get-audio-record';
import { getRecordFullTranscriptionUpdateState } from './get-record-full-transcription';
import { getRecordsUpdateState } from './get-records';
import { updateRecordFullTranscriptionUpdateState } from './update-record-full-transcription';
import { updateRecordUpdateState } from './update-record';
import { uploadFileRecordUpdateState } from './upload-file-record';

export type TRecordState = {
  createRecordResponse?: TCreateRecordResponse;
  deleteRecordResponse?: TDeleteRecordResponse;
  getAudioRecordResponse?: TGetAudioRecordResponse;
  getRecordFullTranscriptionResponse?: TGetRecordFullTranscriptionResponse;
  getRecordsResponse?: TGetRecordsResponse;
  updateRecordFullTranscriptionResponse?: TUpdateRecordFullTranscriptionResponse;
  updateRecordResponse?: TUpdateRecordResponse;
  uploadFileRecordResponse?: TUploadFileRecordResponse;
};

const initialState: TRecordState = {
  createRecordResponse: undefined,
  deleteRecordResponse: undefined,
  getAudioRecordResponse: undefined,
  getRecordFullTranscriptionResponse: undefined,
  getRecordsResponse: undefined,
  updateRecordFullTranscriptionResponse: undefined,
  updateRecordResponse: undefined,
  uploadFileRecordResponse: undefined,
};

const RecordReducer = createReducer(initialState, (handleAction) => [
  handleAction(createRecordAction.success, createRecordUpdateState),
  handleAction(deleteRecordAction.success, deleteRecordUpdateState),
  handleAction(getAudioRecordAction.success, getAudioRecordUpdateState),
  handleAction(getRecordFullTranscriptionAction.success, getRecordFullTranscriptionUpdateState),
  handleAction(getRecordsAction.success, getRecordsUpdateState),
  handleAction(updateRecordFullTranscriptionAction.success, updateRecordFullTranscriptionUpdateState),
  handleAction(updateRecordAction.success, updateRecordUpdateState),
  handleAction(uploadFileRecordAction.success, uploadFileRecordUpdateState),
]);

export default RecordReducer;
