import { createActionCreator } from 'deox';

import { TGetAudioRecordMaterials, TGetAudioRecordResponse } from '@/services/api/record/get-audio-record';

// CONSTANTS

export enum EGetAudioRecordAction {
  GET_AUDIO_RECORD = 'GET_AUDIO_RECORD',
  GET_AUDIO_RECORD_REQUEST = 'GET_AUDIO_RECORD_REQUEST',
  GET_AUDIO_RECORD_SUCCESS = 'GET_AUDIO_RECORD_SUCCESS',
  GET_AUDIO_RECORD_FAILED = 'GET_AUDIO_RECORD_FAILED',
}

// TYPES

export type TGetAudioRecordRequest = {
  type: EGetAudioRecordAction.GET_AUDIO_RECORD_REQUEST;
  payload: {
    materials: TGetAudioRecordMaterials;
    successCallback?: (response: TGetAudioRecordResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAudioRecordSuccess = {
  type: EGetAudioRecordAction.GET_AUDIO_RECORD_SUCCESS;
  payload: { response?: TGetAudioRecordResponse };
};

export type TGetAudioRecordFailed = { type: EGetAudioRecordAction.GET_AUDIO_RECORD_FAILED };

// FUNCTION

export const getAudioRecordAction = {
  request: createActionCreator(
    EGetAudioRecordAction.GET_AUDIO_RECORD_REQUEST,
    (resolve) =>
      (
        materials: TGetAudioRecordMaterials,
        successCallback?: (response: TGetAudioRecordResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAudioRecordRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAudioRecordAction.GET_AUDIO_RECORD_SUCCESS,
    (resolve) =>
      (response?: TGetAudioRecordResponse): TGetAudioRecordSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAudioRecordAction.GET_AUDIO_RECORD_FAILED,
    (resolve) =>
      (error: unknown): TGetAudioRecordFailed =>
        resolve({ error }),
  ),
};
