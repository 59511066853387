import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getRecordsAction } from '@/redux/actions';
import { getRecords, TGetRecordsResponse } from '@/services/api';

// FUNCTION

export function* getRecordsSaga(action: ActionType<typeof getRecordsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getRecords, materials);
    const getRecordsResponse: TGetRecordsResponse = response as TGetRecordsResponse;
    yield put(getRecordsAction.success(getRecordsResponse));
    successCallback?.(getRecordsResponse);
  } catch (err) {
    yield put(getRecordsAction.failure(err));
    failedCallback?.(err);
  }
}
