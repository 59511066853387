import { createReducer } from 'deox';

import { TGetMyProfileResponse, TGetTokenResponse, TLoginResponse } from '@/services/api/auth';
import { getMyProfileAction, getTokenAction, loginAction } from '@/redux/actions';
import { getMyProfileUpdateState } from './get-my-profile';
import { getTokenUpdateState } from './get-token';
import { loginUpdateState } from './login';

export type TAuthState = {
  getMyProfileResponse?: TGetMyProfileResponse;
  getTokenResponse?: TGetTokenResponse;
  loginResponse?: TLoginResponse;
};

const initialState: TAuthState = {
  getMyProfileResponse: undefined,
  getTokenResponse: undefined,
  loginResponse: undefined,
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(getMyProfileAction.success, getMyProfileUpdateState),
  handleAction(getTokenAction.success, getTokenUpdateState),
  handleAction(loginAction.success, loginUpdateState),
]);

export default AuthReducer;
