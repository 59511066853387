import React from 'react';

import Modal from '@/components/Modal';
import Banner from '@/components/Banner';

import { TModalConfirmProps } from './ModalConfirm.types.d';
import './ModalConfirm.scss';

const ModalConfirm: React.FC<TModalConfirmProps> = ({
  visible,
  type,
  title,
  content,
  cancelButtonProps,
  confirmButtonProps,
  loading,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      className="ModalConfirm"
      title={title}
      width={520}
      visible={visible}
      onClose={onClose}
      onSubmit={onSubmit}
      showFooter
      cancelButtonProps={cancelButtonProps}
      confirmButtonProps={confirmButtonProps}
      loading={loading}
    >
      <div className="ModalConfirm-wrapper">
        <Banner type={type} content={content} />
      </div>
    </Modal>
  );
};

export default ModalConfirm;
