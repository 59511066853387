import { all, takeLatest } from 'redux-saga/effects';

import {
  createRecordAction,
  deleteRecordAction,
  getAudioRecordAction,
  getRecordFullTranscriptionAction,
  getRecordsAction,
  updateRecordFullTranscriptionAction,
  updateRecordAction,
  uploadFileRecordAction,
} from '@/redux/actions';

import { createRecordSaga } from './create-record';
import { deleteRecordSaga } from './delete-record';
import { getAudioRecordSaga } from './get-audio-record';
import { getRecordFullTranscriptionSaga } from './get-record-full-transcription';
import { getRecordsSaga } from './get-records';
import { updateRecordFullTranscriptionSaga } from './update-record-full-transcription';
import { updateRecordSaga } from './update-record';
import { uploadFileRecordSaga } from './upload-file-record';

export default function* root(): Generator {
  yield all([
    takeLatest(createRecordAction.request.type, createRecordSaga),
    takeLatest(deleteRecordAction.request.type, deleteRecordSaga),
    takeLatest(getAudioRecordAction.request.type, getAudioRecordSaga),
    takeLatest(getRecordFullTranscriptionAction.request.type, getRecordFullTranscriptionSaga),
    takeLatest(getRecordsAction.request.type, getRecordsSaga),
    takeLatest(updateRecordFullTranscriptionAction.request.type, updateRecordFullTranscriptionSaga),
    takeLatest(updateRecordAction.request.type, updateRecordSaga),
    takeLatest(uploadFileRecordAction.request.type, uploadFileRecordSaga),
  ]);
}
