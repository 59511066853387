import { createActionCreator } from 'deox';

import {
  TUpdateRecordFullTranscriptionMaterials,
  TUpdateRecordFullTranscriptionResponse,
} from '@/services/api/record/update-record-full-transcription';

// CONSTANTS

export enum EUpdateRecordFullTranscriptionAction {
  UPDATE_RECORD_FULL_TRANSCRIPTION = 'UPDATE_RECORD_FULL_TRANSCRIPTION',
  UPDATE_RECORD_FULL_TRANSCRIPTION_REQUEST = 'UPDATE_RECORD_FULL_TRANSCRIPTION_REQUEST',
  UPDATE_RECORD_FULL_TRANSCRIPTION_SUCCESS = 'UPDATE_RECORD_FULL_TRANSCRIPTION_SUCCESS',
  UPDATE_RECORD_FULL_TRANSCRIPTION_FAILED = 'UPDATE_RECORD_FULL_TRANSCRIPTION_FAILED',
}

// TYPES

export type TUpdateRecordFullTranscriptionRequest = {
  type: EUpdateRecordFullTranscriptionAction.UPDATE_RECORD_FULL_TRANSCRIPTION_REQUEST;
  payload: {
    materials: TUpdateRecordFullTranscriptionMaterials;
    successCallback?: (response: TUpdateRecordFullTranscriptionResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateRecordFullTranscriptionSuccess = {
  type: EUpdateRecordFullTranscriptionAction.UPDATE_RECORD_FULL_TRANSCRIPTION_SUCCESS;
  payload: { response: TUpdateRecordFullTranscriptionResponse };
};

export type TUpdateRecordFullTranscriptionFailed = {
  type: EUpdateRecordFullTranscriptionAction.UPDATE_RECORD_FULL_TRANSCRIPTION_FAILED;
};

// FUNCTION

export const updateRecordFullTranscriptionAction = {
  request: createActionCreator(
    EUpdateRecordFullTranscriptionAction.UPDATE_RECORD_FULL_TRANSCRIPTION_REQUEST,
    (resolve) =>
      (
        materials: TUpdateRecordFullTranscriptionMaterials,
        successCallback?: (response: TUpdateRecordFullTranscriptionResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateRecordFullTranscriptionRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateRecordFullTranscriptionAction.UPDATE_RECORD_FULL_TRANSCRIPTION_SUCCESS,
    (resolve) =>
      (response: TUpdateRecordFullTranscriptionResponse): TUpdateRecordFullTranscriptionSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateRecordFullTranscriptionAction.UPDATE_RECORD_FULL_TRANSCRIPTION_FAILED,
    (resolve) =>
      (error: unknown): TUpdateRecordFullTranscriptionFailed =>
        resolve({ error }),
  ),
};
