import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createRecordAction } from '@/redux/actions';
import { createRecord, TCreateRecordResponse } from '@/services/api';

// FUNCTION

export function* createRecordSaga(action: ActionType<typeof createRecordAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createRecord, materials);
    const createRecordResponse: TCreateRecordResponse = response as TCreateRecordResponse;
    yield put(createRecordAction.success(createRecordResponse));
    successCallback?.(createRecordResponse);
  } catch (err) {
    yield put(createRecordAction.failure(err));
    failedCallback?.(err);
  }
}
